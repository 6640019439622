import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Navbar from "../../components/navbar/Navbar";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ConfirmRegistration = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  // const [companyName, setCompanyName] = useState("");
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // useEffect(() => {
  //   const fetchDetails = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/confirmCompany/${key}`
  //       );
  //       const { company_name, username } = response.data;
  //       setCompanyName(company_name);
  //       setUsername(username);
  //     } catch (err) {
  //       setError(
  //         err.response?.data?.message ||
  //           "Failed to fetch company details. Please check the confirmation link."
  //       );
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchDetails();
  // }, [key]);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setValidationError("Passwords do not match!");
      return;
    }
    if (email === password) {
      setValidationError("Password cannot be the same as the email!");
      return;
    }

    setValidationError("");
    setError("");
    setLoading(true);

    try {
      const payload = new URLSearchParams({
        email: email,
        password: password,
        key: key,
      }).toString();

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/confirmCompany`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.success === true) {
        // setSuccess(true);
        navigate("/login");
      } else {
        response.data.error && setError(response.data.error);
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to set password. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f5f5f5",
          padding: 3,
        }}
      >
        <Card
          sx={{
            maxWidth: 500,
            width: "100%",
            borderRadius: 3,
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 3,
              }}
            >
              Update Password
            </Typography>

            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error}
              </Alert>
            )}

            {validationError && (
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                {validationError}
              </Alert>
            )}

            {success ? (
              <Alert severity="success" sx={{ marginBottom: 2 }}>
                Password successfully set! You can now log in.
              </Alert>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <TextField
                  label="Email"
                  type="email"
                  placeholder="Please enter your email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "14px", fontWeight: 500 },
                  }}
                />

                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Please enter your password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "14px", fontWeight: 500 },
                  }}
                />
                <TextField
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "14px", fontWeight: 500 },
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    padding: 1.5,
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Confirm Registration"
                  )}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ConfirmRegistration;

import React, { useContext } from "react";
import "./createSurvey.css";
import Footer from "../../components/navbar/Footer";
import Navbar from "../../components/navbar/Navbar";
import { Button } from "@mui/material";
import { StepsContext } from "../../context/stepsContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchQuestions } from "../../redux/slices/questionsSlice";
import peoplesImage from "../../assets/welcomeBackground.jpg";
import { fetchAreas } from "../../redux/slices/areasSlice";

export default function DoSurvey() {
  const { dispatch: stepsDispatch } = useContext(StepsContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = async () => {
    stepsDispatch({ type: "START" });
    await dispatch(fetchQuestions());
    await dispatch(fetchAreas());
    navigate("/step1");
  };

  return (
    <div className="create-container">
      <Navbar />
      <div
        className="create-banner"
        style={{
          backgroundImage: `url(${peoplesImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <div className="my-[auto] py-[4%] flex flex-col justify-center items-center gap-5">
          <div className="create-main w-[95%] sm:w-[70%]">
            <div className="create-main-header leading-[2rem] text-2xl sm:text-3xl">
              Investors in Sales Questionnaire
            </div>
            <div
              className="create-main-text"
              style={{ width: "100%", textAlign: "left" }}
            >
              <h3 className="text-md sm:text-lg leading-5 sm:leading-6">
                Welcome to the Investors in Sales Survey. Please read the
                following carefully before starting:
              </h3>
              <div
                style={{ marginTop: "12px" }}
                className="text-sm sm:text-md leading-5"
              >
                <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                      {" "}
                      Answer for the Sales Department:
                    </span>
                    As a senior leader, you are completing this survey on behalf
                    of your organisation's Sales department. Do not base your
                    answers solely on your direct team or those reporting to
                    you.
                  </li>
                </ul>
              </div>
              <div
                style={{ marginTop: "12px" }}
                className="text-sm sm:text-md leading-5"
              >
                <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                      {" "}
                      Single Sitting Completion:
                    </span>
                    The survey must be completed in one sitting. Partial
                    progress will not be saved, and you will need to start over
                    if the session is interrupted.
                  </li>
                </ul>
              </div>
              <div
                style={{ marginTop: "8px" }}
                className="text-sm sm:text-md leading-5"
              >
                <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      Structure of the Survey:
                    </span>
                    <ul style={{ marginLeft: "20px", listStyleType: "circle" }}>
                      <li>
                        There are 15 areas, each assessing key aspects of the
                        Sales function.
                      </li>
                      <li>
                        Each area contains 5 questions, focusing on specific
                        metrics and practices.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div
                style={{ marginTop: "8px" }}
                className="text-sm sm:text-md leading-5"
              >
                <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      Answering Format:
                    </span>
                    <ul style={{ marginLeft: "20px", listStyleType: "circle" }}>
                      <li>
                        Each question uses a slider scale. Please ensure you
                        provide a response for all questions.
                      </li>
                      <li>
                        Your responses will contribute to an overall assessment,
                        so completeness is essential.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="create-main-small text-sm sm:text-md font-semibold"
              style={{ display: "flex", flexDirection: "column" }}
            >
              Take your time to reflect on your answers, but remember to
              allocate sufficient uninterrupted time to complete the survey.
              <span style={{ display: "block" }}>
                Thank you for your participation!
              </span>
            </div>
            <div className="create-main-btn">
              <Button
                aria-label="ddd"
                onClick={handleClick}
                className="signupCVbtnSign"
                type="submit"
                variant="contained"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  padding: "8px 30px",
                  textTransform: "capitalize",
                  borderRadius: "999px",
                  backgroundColor: "#3F9BFD",
                  "&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover": {
                    backgroundColor: "#3F9BFD",
                  },
                }}
              >
                Click Here To Start
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import SingleSlider from "./SingleSlider";
import "./createSurvey.css";
import Navbar from "../../components/navbar/Navbar";
import Steps from "../../components/steps/Index";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/navbar/Footer";
import { useSelector, useDispatch } from "react-redux";
import { updateAnswer } from "../../redux/slices/questionsSlice";

export default function StepSix() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const answers = useSelector((state) => state.questions.answers);
  const { areas } = useSelector((state) => state.areas);
  const questions = useSelector((state) => state.questions?.questions);

  const startIndex = 25;
  const pageQuestions = questions.slice(startIndex, startIndex + 5);

  // default answers
  useEffect(() => {
    pageQuestions?.map((question) =>
      dispatch(updateAnswer({ questionId: `q${question.id}`, answer: 65 }))
    );
  }, pageQuestions);

  const handleAnswerChange = (data) => {
    const questionId = data.id;
    const answer = data.value;
    const answerKey = `q${questionId}`;
    dispatch(updateAnswer({ questionId: answerKey, answer: answer }));
  };

  const handlePrevClick = () => {
    dispatch({ type: "STEP" });
    navigate("/step5");
  };

  const handleNextClick = () => {
    dispatch({ type: "STEP" });
    navigate("/step7");
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Navbar />
      <div className="mainContainer" style={{ flex: 1 }}>
        <div className="surveys-sub-container">
          <Steps step={6} active={true} />
          <div className="inst-c">
            <div className="survey-slider-container-title surveys-question-title">
              Employee Wellbeing
            </div>
            <div style={{ color: "red" }}>
              <ul>
                <li>
                  * A score of 100 means you totally agree and 0 means you
                  totally disagree.
                </li>
              </ul>
            </div>
          </div>
          <div className="survey-slider-container inst-sub-first">
            <div className="slider-all">
              {pageQuestions?.map((question, index) => (
                <SingleSlider
                  key={question.id}
                  index={startIndex + index + 1}
                  question={question}
                  pageQuestions={pageQuestions}
                  handleAnswerChange={handleAnswerChange}
                  currentAnswer={answers[question.id] || 0}
                />
              ))}
            </div>
          </div>
          <div className="btn-container gap-5">
            <Button
              onClick={handlePrevClick}
              className="signupCVbtnSign"
              variant="contained"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                padding: "8px 30px",
                textTransform: "capitalize",
                borderRadius: "999px",
                backgroundColor: "#3F9BFD",
                "&:hover": { backgroundColor: "#3F9BFD" },
              }}
            >
              Back
            </Button>
            <Button
              onClick={handleNextClick}
              className="signupCVbtnSign"
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#3F9BFD",
              }}
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                padding: "8px 30px",
                borderRadius: "999px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3F9BFD",
                },
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import DoSurvey from "./pages/surveys/DoSurvey";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Welcome from "./pages/welcome/Welcome";
import "./pages/surveys/cards.css";
import "./pages/surveys/createSurvey.css";
import StepOne from "./pages/surveys/StepOne";
import StepTwo from "./pages/surveys/StepTwo";
import StepThree from "./pages/surveys/StepThree";
import StepFour from "./pages/surveys/StepFour";
import StepFive from "./pages/surveys/StepFive";
import StepSix from "./pages/surveys/StepSix";
import Step7 from "./pages/surveys/Step7";
import Step8 from "./pages/surveys/Step8";
import Step9 from "./pages/surveys/Step9";
import Step10 from "./pages/surveys/Step10";
import Step11 from "./pages/surveys/Step11";
import Step12 from "./pages/surveys/Step12";
import Step13 from "./pages/surveys/Step13";
import Step14 from "./pages/surveys/Step14";
import Step15 from "./pages/surveys/Step15";
import Result from "./pages/result/Result";
import ConfirmRegistration from "./pages/signup/ConfirmRegistration";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./pages/resetPassword/ForgotPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import SurveyUnavailable from "./pages/surveys/SurveyUnavailable";
import { useEffect } from "react";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("companyData"));

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/Login" replace />;
};

const App = () => {
  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.clear();
    };

    // Add event listener for when the user leaves the site
    window.addEventListener("beforeunload", clearLocalStorage);

    // Cleanup event listener to prevent memory leaks
    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/verifyEmail/:key" element={<ConfirmRegistration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/resetPasswordLink/:key" element={<ResetPassword />} />
        <Route
          path="/SurveyUnavailable"
          element={
            <PrivateRoute>
              <SurveyUnavailable />
            </PrivateRoute>
          }
        />
        <Route
          path="/doSurvey"
          element={
            <PrivateRoute>
              <DoSurvey />
            </PrivateRoute>
          }
        />
        <Route
          path="/step1"
          element={
            <PrivateRoute>
              <StepOne />
            </PrivateRoute>
          }
        />
        <Route
          path="/step2"
          element={
            <PrivateRoute>
              <StepTwo />
            </PrivateRoute>
          }
        />
        <Route
          path="/step3"
          element={
            <PrivateRoute>
              <StepThree />
            </PrivateRoute>
          }
        />
        <Route
          path="/step4"
          element={
            <PrivateRoute>
              <StepFour />
            </PrivateRoute>
          }
        />
        <Route
          path="/step5"
          element={
            <PrivateRoute>
              <StepFive />
            </PrivateRoute>
          }
        />
        <Route
          path="/step6"
          element={
            <PrivateRoute>
              <StepSix />
            </PrivateRoute>
          }
        />
        <Route
          path="/step7"
          element={
            <PrivateRoute>
              <Step7 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step8"
          element={
            <PrivateRoute>
              <Step8 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step9"
          element={
            <PrivateRoute>
              <Step9 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step10"
          element={
            <PrivateRoute>
              <Step10 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step11"
          element={
            <PrivateRoute>
              <Step11 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step12"
          element={
            <PrivateRoute>
              <Step12 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step13"
          element={
            <PrivateRoute>
              <Step13 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step14"
          element={
            <PrivateRoute>
              <Step14 />
            </PrivateRoute>
          }
        />
        <Route
          path="/step15"
          element={
            <PrivateRoute>
              <Step15 />
            </PrivateRoute>
          }
        />
        <Route
          path="result"
          element={
            <PrivateRoute>
              <Result />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

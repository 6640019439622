import React from "react";
import { Dialog, DialogContent, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const RegistrationPopup = ({ open, handleClose, popupText }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        sx={{
          backgroundColor: "#24a9dd",
          color: "white",
          padding: { xs: "40px 10px", sm: "40px 15px", md: "40px 20px" },
        }}
      >
        <Typography variant="body1" sx={{ fontSize: "16px" }}>
          {popupText}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            border: "2px solid black",
            p: "2px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationPopup;

import React, { useEffect, useState } from "react";
import { Box, Slider } from "@mui/material";
import "./createSurvey.css";

export default function SingleSlider({
  index,
  question,
  handleAnswerChange,
  currentAnswer,
}) {
  const [sliderValue, setSliderValue] = useState(currentAnswer || 65);

  useEffect(() => {
    setSliderValue(currentAnswer || 65);
  }, [currentAnswer]);

  const handleChange = (e, newValue) => {
    setSliderValue(newValue);
    handleAnswerChange({ id: question.id, value: newValue, altered: true });
  };

  return (
    <div className="slider-conti">
      <div
        className="sliderNumber"
        // style={{
        //   fontSize: "15px",
        //   backgroundColor: value.altered ? "#92D2C4" : "white",
        //   color: value.altered ? "white" : "black",
        // }}
        style={{
          fontSize: "15px",
          backgroundColor: "white",
          color: "black",
        }}
      >
        {index}
      </div>
      <div className="sliderMain">
        <div className="slider-title">{question?.question}</div>
        <div className="slider-image">
          <Box>
            <Slider
              value={sliderValue}
              onChange={handleChange}
              color="primary"
              sx={{
                "& .MuiSlider-thumb": {
                  width: "36px",
                  height: "36px",
                  backgroundColor: "#92D2C4",
                  position: "relative",
                  "&::after": {
                    content: `"${sliderValue}"`,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#C83C70",
                    color: "#fff",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  },
                },
                "& .MuiSlider-track": {
                  color: "#45B5B6",
                },
                "& .MuiSlider-rail": {
                  color: "#E6E6E6",
                },
              }}
              style={{
                color: "#93d3c6",
                height: "20px",
              }}
              min={0}
              max={100}
            />
          </Box>
          {/* Additional Texts Section */}
          <Box
            sx={{
              // display: "grid",
              // gridTemplateColumns: "repeat(5, 1fr)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "8px",
              // marginTop: "6px",
              fontSize: { xs: "6px", sm: "10px", md: "12px" },
              opacity: "0.8",
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                padding: "8px",
                maxWidth: "33%",
                // borderRight: "1px solid gray",
              }}
            >
              {question?.response_1}
            </Box>
            {/* <Box
              sx={{
                textAlign: "center",
                padding: "8px",
                borderRight: "1px solid gray",
              }}
            >
              {question?.response_2}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                padding: "8px",
                borderRight: "1px solid gray",
              }}
            >
              {question?.response_3}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                padding: "8px",
                borderRight: "1px solid gray",
              }}
            >
              {question?.response_4}
            </Box> */}
            <Box
              sx={{
                textAlign: "left",
                padding: "8px",
                maxWidth: "33%",
              }}
            >
              {question?.response_5}
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import peoplesImage from "../../assets/welcomeBackground.jpg";
import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import Footer from "../../components/navbar/Footer";

const SurveyUnavailable = () => {
  const location = useLocation();
  const { surveyDate, overallScore } = location.state || {};
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false);

  const handleDownloadReport = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getReport/${companyData?.id}/${companyData?.admin_email}`
      );
      window.open(
        `${process.env.REACT_APP_BASE_URL}/showPdf/${response.data}`,
        "_blank"
      );
    } catch (error) {
      console.error("Error fetching Report:", error);
    }
  };

  const handleDownloadCertificate = async () => {
    setIsLoadingCertificate(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getCertificate/${companyData?.id}`
      );
      if (response.data) {
        window.open(
          `${process.env.REACT_APP_BASE_URL}/showPdf/${response.data}`,
          "_blank"
        );
      }
    } catch (error) {
      console.error("Error fetching Report:", error);
    } finally {
      setIsLoadingCertificate(false);
    }
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          width: "100%",
          //   height: "100vh",
          display: "flex",
          flexDirection: "column",
          paddingTop: "60px",
        }}
      >
        {/* image */}
        <Box
          sx={{
            height: { xs: "40vh", sm: "50vh", md: "60vh" },
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={peoplesImage}
            style={{
              width: "100%",
              height: "auto", // Maintain aspect ratio
              display: "block", // Prevent extra space below the image
            }}
          />
        </Box>
        {/* body */}
        <Container
          sx={{
            padding: { xs: "12px", sm: "16px" },
            textAlign: "center",
            fontSize: { xs: "14px", sm: "16px", md: "18px" },
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              padding: "16px",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography
                style={{
                  fontSize: "1.5rem",
                  margin: "8px 0",
                  fontWeight: "600",
                }}
              >
                Hello {companyData?.admin_firstname}
              </Typography>
              <Typography
                style={{
                  fontSize: "1.5rem",
                  margin: "8px 0",
                  fontWeight: "600",
                }}
              >
                Welcome back...
              </Typography>
            </Box>

            {/* Image Section */}
            {companyData?.url && (
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={companyData?.url}
                  alt="Company Logo"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "300px",
                  }}
                />
              </Box>
            )}
          </Box>

          <Typography
            sx={{
              fontSize: "1rem",
              margin: "8px 0",
              fontWeight: "600",
            }}
          >
            We can see that you have completed the survey on {surveyDate}.
            Please try again in three months.
          </Typography>
          <Typography
            style={{
              fontSize: "1.8rem",
              margin: "16px 0",
              fontWeight: "600",
            }}
          >
            YOUR SCORE : {overallScore}
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "15px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadCertificate}
              disabled={overallScore < 60}
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                padding: "8px 30px",
                textTransform: "capitalize",
                backgroundColor: "#2691a3",
                borderRadius: "999px",
                "&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover": {
                  backgroundColor: "#2691a3",
                },
              }}
            >
              {isLoadingCertificate ? "Downloading..." : "Download Certificate"}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDownloadReport}
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                padding: "8px 30px",
                textTransform: "capitalize",
                borderRadius: "999px",
                backgroundColor: "#24a9dd",
                "&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover": {
                  backgroundColor: "#24a9dd",
                },
              }}
            >
              Download Report
            </Button>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default SurveyUnavailable;

import React, { useEffect, useState } from "react";
import "../surveys/createSurvey.css";
import Footer from "../../components/navbar/Footer";
import Navbar from "../../components/navbar/Navbar";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";

export default function Result() {
  const answers = useSelector((state) => state.questions.answers);
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  const [isPassed, setIsPassed] = useState(false);
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false);
  // const values = Object.values(answers);
  // const sum = values.reduce((acc, val) => acc + val, 0);
  // const average = sum / values.length;

  // Post survey data on initial render
  useEffect(() => {
    const postSurveyData = async () => {
      if (companyData && Object.keys(answers).length > 0) {
        const payload = {
          ...answers,
          member_id: 0,
          company_id: companyData.id,
          email: companyData.admin_email,
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/surveyData`,
            new URLSearchParams(payload),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          setIsPassed(response.data.pass);
        } catch (error) {
          console.error("Error during API call:", error);
        }
      }
    };

    postSurveyData();
  }, [answers, companyData]);

  const handleDownloadReport = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getReport/${companyData?.id}/${companyData?.admin_email}`
      );
      window.open(
        `${process.env.REACT_APP_BASE_URL}/showPdf/${response.data}`,
        "_blank"
      );
    } catch (error) {
      console.error("Error fetching Report:", error);
    }
  };

  const handleDownloadCertificate = async () => {
    setIsLoadingCertificate(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getCertificate/${companyData?.id}`
      );
      if (response.data) {
        window.open(
          `${process.env.REACT_APP_BASE_URL}/showPdf/${response.data}`,
          "_blank"
        );
      }
    } catch (error) {
      console.error("Error fetching Report:", error);
    } finally {
      setIsLoadingCertificate(false);
    }
  };

  return isPassed ? (
    <div className="create-container">
      <Navbar />
      <div className="create-banner">
        <div
          style={{
            padding: "80px 30px 30px 30px",
            textAlign: "center",
            flexGrow: 1,
            margin: "auto",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            width: "80%",
            maxWidth: "800px",
            minWidth: "300px",
          }}
        >
          <p
            style={{
              fontWeight: "500",
              fontSize: "24px",
              textTransform: "capitalize",
              color: "#333",
              marginBottom: "10px",
            }}
          >
            {" "}
            Thank you for completing the ISP Awards questionnaire
          </p>

          <p
            style={{
              fontWeight: "500",
              fontSize: "32px",
              textTransform: "capitalize",
              color: "#333",
              marginBottom: "4px",
            }}
          >
            {" "}
            Congratulations!
          </p>

          <p
            style={{
              fontSize: "16px",
              color: "#333",
              marginBottom: "40px",
              fontWeight: "500",
            }}
          >
            Based on the information you provided, we can confirm that your
            organisation invests in sales. The certificate is now available for
            you to download. You can also download a copy of your report to see
            where you are doing well and where you can further improve.
          </p>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "15px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadCertificate}
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                padding: "8px 30px",
                textTransform: "capitalize",
                backgroundColor: "#2691a3",
                borderRadius: "999px",
                "&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover": {
                  backgroundColor: "#2691a3",
                },
              }}
            >
              {isLoadingCertificate ? "Downloading..." : "Download Certificate"}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDownloadReport}
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                padding: "8px 30px",
                textTransform: "capitalize",
                borderRadius: "999px",
                backgroundColor: "#24a9dd",
                "&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover": {
                  backgroundColor: "#24a9dd",
                },
              }}
            >
              Download Report
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  ) : (
    <div className="create-container">
      <Navbar />
      <div className="create-banner">
        <div
          style={{
            padding: "80px 30px 30px 30px",
            textAlign: "center",
            flexGrow: 1,
            margin: "auto",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            width: "80%",
            maxWidth: "800px",
            minWidth: "300px",
          }}
        >
          <p
            style={{
              fontWeight: "500",
              fontSize: "24px",
              textTransform: "capitalize",
              color: "#333",
              marginBottom: "10px",
            }}
          >
            {" "}
            Thank you for completing the ISP Awards questionnaire
          </p>

          <p
            style={{
              fontSize: "16px",
              color: "#333",
              marginBottom: "20px",
              fontWeight: "500",
            }}
          >
            We are sorry to let you know that based on the information provided
            your organisation has not qualified. You can download the report to
            see where you are doing well and where you can improve. Please
            address those areas and try again after three months
          </p>

          <p
            style={{
              fontSize: "16px",
              color: "#333",
              marginBottom: "40px",
              fontWeight: "700",
            }}
          >
            Please try again in 3 months.
          </p>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "15px" }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDownloadReport}
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                padding: "8px 30px",
                textTransform: "capitalize",
                borderRadius: "999px",
                backgroundColor: "#24a9dd",
                "&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover": {
                  backgroundColor: "#24a9dd",
                },
              }}
            >
              Download Report
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

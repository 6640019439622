import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// import { loginUser } from "../../store/slices/auth";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { TextField, Button, CircularProgress } from "@mui/material";
import LoginLogo from "../../assets/isp_logo_new.png";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");
  // const dispatch = useDispatch();

  const handleLogin = async () => {
    setFormError("");
    if (!username.trim() || !password.trim()) {
      setFormError("Please enter username and password.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkLogin`,
        new URLSearchParams({
          username,
          password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // Handle successful response
      if (response.data.success) {
        const companyData = response.data.company;
        localStorage.setItem("companyData", JSON.stringify(companyData));
        if (response.data?.allowSurvey) {
          navigate("/doSurvey");
        } else {
          navigate("/SurveyUnavailable", {
            state: {
              surveyDate: response.data.surveyDate,
              overallScore: response.data.overallScore,
            },
          });
        }
      } else {
        setFormError("Login failed. Please try again.");
      }
    } catch (error) {
      setFormError("An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = () => {
    navigate("/register");
  };

  const handleForgotPasswordClick = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-200">
      <div className="bg-white rounded-lg shadow-lg px-8 py-4 w-full max-w-lg">
        <div className="flex flex-col items-center mb-4">
          <img
            src={LoginLogo}
            className="w-auto h-40 object-contain mb-0"
            alt="Logo"
          />
        </div>
        <div className="bg-[#04192E] text-white rounded-lg p-6 mb-5">
          <p className="mb-12 text-center text-lg">
            Welcome to the ISP Awards in Sales Excellence.
          </p>
          <div className="mb-4">
            <TextField
              // variant="outlined"
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              className="mb-4"
              InputProps={{
                style: { borderRadius: "999px", backgroundColor: "white" },
              }}
            />
          </div>
          <div className="relative mb-4">
            <TextField
              // variant="outlined"
              type={showPassword ? "text" : "password"}
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "999px",
                  backgroundColor: "white",
                  paddingRight: "40px",
                },
              }}
            />
            <span
              className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer text-[#6f6f6f]"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
            </span>
          </div>
          {formError && <p className="text-red-600 mt-2 mb-2">{formError}</p>}
          <Button
            variant="contained"
            fullWidth
            className="mb-4"
            style={{
              backgroundColor: "#4FB5B6",
              color: "white",
              borderRadius: "999px",
              fontWeight: "bold",
            }}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
          </Button>
          <Button
            variant="text"
            fullWidth
            style={{
              marginTop: "10px",
              color: "white",
              textDecoration: "underline",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
            onClick={handleSignup}
          >
            Don't have an account? Register now.
          </Button>
          <Button
            variant="text"
            fullWidth
            style={{
              color: "white",
              textDecoration: "underline",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
            onClick={handleForgotPasswordClick}
          >
            Forgot Password?
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import Navbar from "../../components/navbar/Navbar";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  const handleSubmit = async () => {
    if (!email) {
      setValidationError("Please enter your email");
      return;
    }
    setValidationError("");
    setError("");
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getPasswordResetLink/${email}`
      );
      if (response.data.message) {
        setSuccess(true);
        setSuccessText(response.data.message);
      } else {
        response.data.error && setError(response.data.error);
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to set password. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f5f5f5",
          padding: 3,
        }}
      >
        <Card
          sx={{
            maxWidth: 500,
            width: "100%",
            borderRadius: 3,
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 3,
              }}
            >
              Forgot Password
            </Typography>

            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error}
              </Alert>
            )}

            {validationError && (
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                {validationError}
              </Alert>
            )}

            {success ? (
              <Alert severity="success" sx={{ marginBottom: 2 }}>
                {successText}
              </Alert>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <TextField
                  label="Email"
                  type="email"
                  placeholder="Please enter your email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "14px", fontWeight: 500 },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    padding: 1.5,
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Reset my password"
                  )}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ForgotPassword;
